<template>
    <div class="component-nimbus-datatable">
        <Toast v-if="enableToastProps"/>
        <DataTable
            @export-to-csv="exportCSV"
            :value="rows"
            :lazy="true"
            :paginator="paginator"
            :stripedRows="stripedRows"
            :rows="rowCount"
            v-model:filters="filters"
            ref="dt"
            dataKey="id"
            :totalRecords="totalRecords"
            :loading="loading"
            @page="onPage($event)"
            @sort="onSort($event)"
            @filter="onFilter($event)"
            :filterDisplay="filterMode"
            :globalFilterFields="globalFilters"
            responsiveLayout="scroll"
            v-model:selection="selectedRows"
            columnResizeMode="fit"
            :resizableColumns="false"
            @select-all-change="onSelectAllChange"
            @row-select="onRowSelect"
            :rowsPerPageOptions="[5, 10, 20, 50, 100]"
            @row-unselect="onRowUnselect"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :currentPageReportTemplate="$t('lbl_showing', 'Showing') + ` {first} to {last} of {totalRecords}`"
            editMode="row"
            v-model:editingRows="editingRows"
            @row-edit-save="onRowEditSave"
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse"
            v-model:expandedRows="expandedRows"
            @rowReorder="onRowReorder"
            :row-class="rowClass"
            :showGridlines="showGridlinesProps"
            class="p-datatable-sm"
        >
            <template #header v-if="showHeaderContainerProps">
                <div v-if="showCustomFilter">
                    <h6>{{ $t('lbl_search_filters', 'Search Filters') }} <span class="custom-date-line"></span></h6>
                    <div class="flex mb-2">
                        <slot name="custom-filter"></slot>
                    </div>
                </div>
                
                <div class="flex flex-column md:flex-row justify-content-between gap-2 mb-3">
                    <div class="flex flex-column md:flex-column-reverse lg:flex-row gap-2">
                        <Button
                            v-if="showCollapsedAll"
                            @click="collapseAll()"
                            type="button" icon="pi pi-minus"
                            :label="$t('btn_collapsed_all', 'Collapsed All')"
                            class="p-button-outlined p-button-sm"
                        />
                        <Button
                            v-if="showClearAll"
                            @click="clearFilter()"
                            type="button"
                            icon="pi pi-filter-slash"
                            v-tooltip.bottom="$t('btn_clear_all_filters', 'Clears all filters')"
                            :label="$t('btn_clear_all_filters', 'Clear All Filters')"
                            class="p-button-outlined p-button-secondary"
                        />
                        <MultiSelect
                            v-if="showColToggle" :modelValue="selectedColumns" :options="defaultColumns"
                            optionLabel="header" @update:modelValue="onToggle" placeholder="Add more data"
                            display="chip" :max-selected-labels="3" style="max-width: unset"
                            :pt="multiSelectPassThrough"
                        />

                        <slot name="dt-head-slot"></slot>
                    </div>
                    <div v-if="showGlobalSearchProps">
                        <span class="p-input-icon-left p-input-icon-right p-float-label block">
                            <i class="pi pi-search" />
                            <InputText id="search" v-model="globalSearch" v-tooltip.top="searchTooltip" class="w-full" />
                            <label for="Search" class="text-base">{{ $t('lbl_keyword_search', 'Keyword Search') }}</label>
                            <i class="pi pi-times-circle" :style="{ cursor: 'pointer' }" @click="clearSearch" />
                        </span>
                    </div>
                </div>
            </template>
            <Column :rowReorder="showReorder" headerStyle="width: 3rem" :header="reorderHeaderName" :reorderableColumn="false" v-if="showReorder" />
            <Column :expander="showRowExpand" headerStyle="width: 3rem" v-if="showRowExpand" />
            <Column selectionMode="multiple" headerStyle="width: 3em" v-if="selectMultiple"></Column>
            <Column
                v-for="(col, index) of selectedColumns"
                :field="col.field"
                :header="col.header"
                :sortable="col.sortable"
                :ref="col.field"
                :filterField="col.field"
                :filterMatchMode="col.filterMatchMode"
                :key="col.field + '_' + index"
                :showFilterMatchModes="col.showFilterMatchModes"
                :datatype="col.type"
                :bodyStyle="alignPositionStyle(col.align)"
                :headerClass="alignHeaderPositionClass(col.align)"
            >
                <!-- Body -->
                <template #body="slotProps">
                    <!-- Check if row data is a form -->
                    <div v-if="slotProps.data.isForm && hasPermission">
                        <div v-if="col.formSlot == 'form-action-slot'">
                            <center>
                                <span v-tooltip="'Remove'" class="pi pi-times cursor-pointer action-icon" @click="formsRow({ increment: false, index: slotProps.index })"></span>
                            </center>
                        </div>

                        <slot v-else :name="col.formSlot" :column="col" :rowCount="slotProps.index" :items="requestArray"></slot>
                    </div>
                    <div
                        v-else
                        :class="{ 'dt-action-slot': col.slotName === 'action-slot' }"
                    >
                        <div v-if="col.slotName !== ''">
                            <slot :name="col.slotName" :data="slotProps" :column="col" :items="filterItems[col.field]"></slot>
                        </div>
                        <div v-else>
                            {{ parseRowValue(slotProps.data, col) }}
                        </div>
                    </div>
                </template>

                <!--Editor mode-->
                <template #editor="slotProps">
                    <div v-if="col.formSlot != 'form-action-slot'">
                        <slot :name="col.formSlot" :column="col" :rowCount="slotProps.index" :items="requestArray"></slot>
                    </div>
                </template>
                <!-- Filter -->
                <template #filter="{ filterModel, filterCallback }" v-if="col.filter">
                    <div v-if="col.type === 'checkbox'">
                        <div class="mb-3">
                            <strong>{{ col.header }}</strong>
                        </div>
                        <MultiSelect v-model="filterModel.value" :options="filterItems[col.field]" optionLabel="name" :placeholder="'Select ' + col.header" />
                    </div>
                    <div v-else-if="col.type === 'date'">
                        <div class="mb-3">
                            <strong>{{ col.header }}</strong>
                        </div>
                        <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                    </div>
                    <div v-else>
                        <InputText v-if="filterModel != null" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="`Search by ` + col.header" />
                    </div>
                </template>
            </Column>
            <Column v-if="(mode == 'edit' || mode == 'mix') && hasPermission" :rowEditor="true" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center"></Column>
            <template #footer v-if="showFooter && hasPermission">
                <span v-if="mode != 'edit'" style="float: right">
                    <SplitButton label="Add More Rows" class="p-button-outlined mr-2 nimbus-button-add-more" icon="pi pi-plus-circle" :model="items" v-if="displayAddMoreRows"></SplitButton>
                    <Button icon="pi pi-plus-circle" label="Add 1 Row" v-if="displayAddRow" @click="formsRow({ increment: true })" class="p-button-outlined p-button-secondary" />
                </span>
                <span v-if="showFormSave">
                    <slot name="form-save-slot" :data="requestArray"></slot>
                </span>
            </template>
            <template #empty>
                <div v-if="isSearchResult" class="center m-4">
                    <i class="pi pi-search pb-3 p-text-xl-7"></i>
                    <div>No search results</div>
                </div>
                <p v-else class="italic">No records found.</p>
            </template>
            <template #paginatorstart>
                <Button v-if="showExport" :label="$t('btn_export', 'Export')" type="button" icon="pi pi-upload" class="p-button-outlined p-button-help" @click="exportCSV($event)" />
            </template>
            <template #expansion="slotProps">
                <slot name="expanded-slot" :data="slotProps.data"></slot>
            </template>
        </DataTable>
    </div>
</template>

<script>
import { ref, onMounted, toRefs, watch } from 'vue';
import UserAuthenticationService from '../service/UserAuthenticationService';
import DataTableService from '../service/DataTableService';
import axios from 'axios';
import NotificationService from '../service/NotificationService';
import NimbusUtilities, { insertNewModel } from '@/service/NimbusUtilities';
import { useToast } from 'primevue/usetoast';
import EventBus from '../event-bus';
import NimbusService from '@/service/NimbusService';
import { getDelay } from '@/service/AutoCompleteService';
import { useRoute, useRouter } from 'vue-router';

export default {
    props: {
        api: String,
        exportApi: String,
        tableName: {
            type: String,
            default: 'default',
        },
        columnProps: Array,
        filterProps: Object,
        globalFiltersProps: Array,
        selectMultiple: Boolean,
        filterMode: String,
        searchTooltipProps: {
            type: String,
            default: 'Search for ID & Name',
        },
        sortDefaultProps: Object,
        exportProps: {
            type: Boolean,
            default: true,
        },
        colToggleProps: {
            type: Boolean,
            default: true,
        },
        clearAllProps: {
            type: Boolean,
            default: true,
        },
        isDTForm: {
            type: Boolean,
            default: false,
        },
        rowFormRequestArray: {
            type: Array,
            default: () => [],
        },
        paginatorProps: {
            type: Boolean,
            default: true,
        },
        showGlobalSearchProps: {
            type: Boolean,
            default: true,
        },
        showHeaderContainerProps: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: false,
        },
        requestModelProps: Object,
        permissionProps: {
            type: String,
            default: null,
        },
        mode: {
            type: String,
            default: 'create', //create, edit, fulledit, mix
        },
        submitApiForms: {
            type: String,
            default: '',
        },
        showFormSave: {
            type: Boolean,
            default: false,
        },
        showRowExpand: {
            type: Boolean,
            default: false,
        },
        showReorder: {
            type: Boolean,
            default: false,
        },
        reorderHeaderName: {
            type: String,
            default: 'Sequence',
        },
        reorderApiForms: {
            type: String,
            default: '',
        },
        childObjectName: {
            type: String,
            default: 'children',
        },
        showCollapsedAll: {
            type: Boolean,
            default: false,
        },
        reorderColumnName: {
            type: String,
            default: 'sequence',
        },
        showGridlinesProps: {
            type: Boolean,
            default: false,
        },
        stripedRows: {
            type: Boolean,
            default: true,
        },
        displayAddMoreRows: {
            type: Boolean,
            default: true,
        },
        displayAddRow: {
            type: Boolean,
            default: true,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
        hasDefaultRowForm: {
            type: Boolean,
            default: true,
        },
        showCustomFilter : {
            type : Boolean,
            default : false
        },
        // value should have a valid unit i.e., px, em, rem, %, etc.
        customFilterContainerWidth: {
            type: String,
            required: false,
            default: '100%',
        },
        multiSelectPassThrough: {
            type: Object,
            default: () => {}
        },
        defaultSelectedColumnCountProps: {
            type: Number,
            default: null
        },
        enableToastProps: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        onMounted(() => {
            loading.value = true;
            lazyParams.value = {
                first: 1,
                rows: showAllData.value ? -1 : dt.value.rows,
                sortField: null,
                sortOrder: 1,
                filters: filters,
            };
            isReady.value = true;
            loadFilters();
            loadLazyData();

            EventBus.on('reload-table', () => {
                loadLazyData();
            });

            //Check permission
            hasPermission.value = userService.value.hasPermission(props.permissionProps);
        });

        const {
            api,
            exportApi,
            tableName,
            searchTooltipProps,
            sortDefaultProps,
            exportProps,
            colToggleProps,
            clearAllProps,
            isDTForm,
            rowFormRequestArray,
            paginatorProps,
            requestModelProps,
            submitApiForms,
            reorderApiForms,
            childObjectName,
            reorderColumnName,
            showAll,
            hasDefaultRowForm,
        } = toRefs(props);

        const dt = ref();
        const tableId = ref(tableName.value);
        const loading = ref(false);
        const columnFilter = ref(false);
        const isPaginate = ref(false);
        const totalRecords = ref(0);
        const rows = ref();
        const selectedRows = ref();
        const selectAll = ref(false);
        const filters = ref([]);
        const lazyParams = ref({});
        const defaultColumns = ref([]);
        const globalFilters = ref([]);
        const selectedColumns = ref([]);
        const globalSearch = ref('');
        const filterItems = ref([]);
        const searchTooltip = ref(searchTooltipProps.value);
        const sortDefault = ref(sortDefaultProps.value);
        const showExport = ref(exportProps.value);
        const showColToggle = ref(colToggleProps.value);
        const showClearAll = ref(clearAllProps.value);
        const rowCount = ref(20);
        const isSearchResult = ref(false);
        const disableTeleport = ref(false);
        const isReady = ref(false);
        const rowFormCount = ref(rowFormRequestArray.value.length);
        const rowForm = ref(isDTForm.value);
        const requestArray = ref(rowFormRequestArray.value);
        const paginator = ref(paginatorProps.value);
        const requestModel = ref(requestModelProps.value);
        const showAllData = ref(showAll.value);
        const hasDefaultForm = ref(hasDefaultRowForm.value);
        const items = ref([
            {
                label: 'Add 3 rows',
                command: () => {
                    addNumberOfRows(3);
                },
            },
            {
                label: 'Add 5 rows',
                command: () => {
                    addNumberOfRows(5);
                },
            },
            {
                label: 'Add 10 rows',
                command: () => {
                    addNumberOfRows(10);
                },
            },
        ]);
        const numberOfRows = ref();
        const hasPermission = ref(false);
        const editingRows = ref([]);
        const nimibusService = ref(new NimbusService());
        const submitApi = ref(submitApiForms.value);
        const reorderApi = ref(reorderApiForms.value);
        const expandedRows = ref([]);
        const childRow = ref(childObjectName.value);
        const reorderColName = ref(reorderColumnName.value);
        const awaitingSearch = ref(false);
        const debounce = ref(null);

        //services
        const userService = ref(new UserAuthenticationService());
        const dataTableService = ref(new DataTableService());
        const nimbusUtils = ref(new NimbusUtilities());
        const notifService = new NotificationService();
        const toast = useToast();
        const router = useRouter();

        const parseRowValue = (data, column) => {
            return nimbusUtils.value.parseRowValue(data, column);
        };

        const onToggle = (val) => {
            // this sort by order of column
            val = val.sort((a, b) => (a.order > b.order ? 1 : -1));
            selectedColumns.value = val;
            dataTableService.value.setColumnItems(tableId.value, selectedColumns.value);
        };

        // overriding the global search filter
        // update when global search is trigger
        watch(globalSearch, (value) => {
            // delay the function for the user when typing
            clearTimeout(debounce.value);
            debounce.value = setTimeout(() => {
                lazyParams.value.filters['global'].value = globalSearch.value.toLowerCase();
                isSearchResult.value = !!globalSearch.value;
                loadLazyData();
            }, getDelay());
        });

        const setColumns = () => {
            if (dataTableService.value.getColumnItems(tableId.value)) {
                selectedColumns.value = dataTableService.value.getColumnItems(tableId.value);
            }
        };
        const loadFilters = () => {
            if (dataTableService.value.getFilterItems(tableId.value)) {
                lazyParams.value.filters = dataTableService.value.getFilterItems(tableId.value);
                filters.value = dataTableService.value.getFilterItems(tableId.value);
            }
        };

        const exportCSV = () => {
            dt.value.exportCSV();
            notifService.fire(toast, 'success', 'Message', 'Successfully downloaded a file', 3000);
        };

        const clearSearch = () => {
            loading.value = true;
            globalSearch.value = ''; // remove custom model global search
            isSearchResult.value = false;
            loadLazyData();
        };

        const clearFilter = () => {
            loading.value = true;
            isSearchResult.value = false;

            initFilter();
            resetColumnIcons();
            loadLazyData();
        };

        const initFilter = () => {
            // remove localstorage
            dataTableService.value.removeColumnFilterItems(tableId.value);

            globalSearch.value = ''; // remove custom model global search
            selectedColumns.value = defaultColumns.value;
            // load initial state
            lazyParams.value = {
                first: 1,
                rows: dt.value.rows,
                sortField: null,
                sortOrder: null,
                filters: {
                    global: { value: null, matchMode: 'contains' },
                },
            };
        };

        const loadLazyData = () => {
            loading.value = true;
            if (lazyParams.value.sortField != null) {
                lazyParams.value.sortOrder = lazyParams.value.sortOrder == 1 ? 'asc' : 'desc';
            } else {
                lazyParams.value.sortField = sortDefault.value.field;
                lazyParams.value.sortOrder = sortDefault.value.sort;
            }

            const params = lazyParams.value;

            const config = {
                headers: { Authorization: `Bearer ${userService.value.getToken()}` },
                params: {
                    dt_params: JSON.stringify(params),
                    global : lazyParams.value.filters.global?.value
                },
            };

            axios
                .get(api.value, config)
                .then((d) => {
                    awaitingSearch.value = false;

                    let lsFilterPreference = dataTableService.value.getFilterItems(tableId.value);

                    if (lsFilterPreference == null) {
                        filters.value = d.data.filterList['filters'];
                        dataTableService.value.setFilterItems(tableId.value, d.data.filterList['filters']);
                    }

                    defaultColumns.value = d.data.columnList;

                    if (selectedColumns.value.length <= 0) {
                        selectedColumns.value = d.data.columnList;
                        setColumns();
                    } else {
                        selectedColumns.value = d.data.columnList.filter((col) => col.show);
                    }

                    if (props.defaultSelectedColumnCountProps && typeof(props.defaultSelectedColumnCountProps) === 'number') {
                        selectedColumns.value = selectedColumns.value.slice(0, props.defaultSelectedColumnCountProps);
                    }

                    filterItems.value = d.data.filters;

                    rows.value = d.data.data.results;
                    totalRecords.value = d.data.totalRecords;
                    emit('total-records', totalRecords.value);
                    loading.value = false;

                    //If datatable is being used as form
                    if (rowForm.value) {
                        if (props.mode == 'create' || props.mode == 'mix') {
                            populateFormsToRow();
                        }

                        if (props.mode == 'edit') {
                            //populate rows to forms
                            requestArray.value = [];

                            for (const row of rows.value) {
                                requestArray.value.push(row);
                            }
                        }

                        if (props.mode == 'fulledit') {
                            //populate rows to forms with isForm attribute
                            requestArray.value = [];

                            for (const row of rows.value) {
                                requestArray.value.push({
                                    isForm: true,
                                    ...row,
                                });
                            }
                            rows.value = [];
                            rows.value = requestArray.value;
                        }
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        router.push('/page-unknown');
                    } else {
                        notifService.fire(toast, 'warn', '', 'There was an error fetching the data. Please contact the administrator for support', 3000);
                        loading.value = false;
                    }
                });
        };
        const onPage = (event) => {
            lazyParams.value = event;
            isPaginate.value = true;
            loadLazyData();
        };
        const onSort = (event) => {
            lazyParams.value = event;
            loadLazyData();
        };
        const alignPositionStyle = (position = 'left') => {
            return `text-align: ${position}`;
        };
        const alignHeaderPositionClass = (position = 'left') => {
            switch (position) {
                case 'right':
                    return 'header-right';
                case 'center':
                    return 'header-center';
                default:
                    return null;
            }
        };
        const onFilter = (filters) => {
            columnFilter.value = true;
            lazyParams.value.filters = filters.filters;
            isSearchResult.value = true;
            // set filter items to localStorage
            dataTableService.value.setFilterItems(tableId.value, lazyParams.value.filters);
            loadLazyData();
        };
        const onSelectAllChange = (event) => {
            const selectAll = event.checked;

            if (selectAll) {
                axios.get(api.value, JSON.stringify(lazyParams.value), userService.value.getBearer()).then((d) => {
                    selectAll.value = true;
                    selectedRows.value = d.data.results.data;
                });
            } else {
                selectAll.value = false;
                selectedRows.value = [];
            }
        };
        const onRowSelect = () => {
            selectAll.value = selectedRows.value.length === totalRecords.value;
        };
        const onRowUnselect = () => {
            selectAll.value = false;
        };

        const resetColumnIcons = () => {
            let columnElement = document.getElementsByClassName('p-sortable-column-icon');

            for (let colElm of columnElement) {
                colElm.classList.remove('pi-sort-amount-up-alt');
                colElm.classList.remove('pi-sort-amount-down');

                if (!colElm.classList.contains('pi-sort-alt')) {
                    colElm.classList.add('pi-sort-alt');
                }
            }
            let headerElement = document.getElementsByClassName('p-sortable-column');
            for (let headerEl of headerElement) {
                headerEl.classList.remove('p-highlight');
            }
        };

        const formsRow = (data) => {
            if (data.increment) {
                let model = insertNewModel(requestModel.value);

                rowFormCount.value++;

                if (props.mode == 'fulledit' || props.mode == 'mix') {
                    requestArray.value.push(convertToProxy(model, rowFormCount.value));
                    rows.value = requestArray.value;
                }

                if (props.mode == 'create') {
                    requestArray.value.push(model);
                    rows.value = [convertToProxy(model, rowFormCount.value), ...rows.value];
                }
            } else {
                // Final index must be reset
                if (hasDefaultForm.value) {
                    // check if should have default form value
                    if (data.index === 0 && requestArray.value.length === 1) {
                        formsRow({ increment: true });
                    }
                }

                requestArray.value.splice(data.index, 1);

                //remove from rows
                if (props.mode !== 'fulledit') {
                    rows.value.splice(data.index, 1);
                }

                rowFormCount.value--;
            }
        };

        const handler = {
            get(target, property) {
                return target[property];
            },
        };

        const populateFormsToRow = () => {
            let rowNumber = rows.value.length + 1;

            if (props.mode == 'create') {
                //reset rows if create
                rows.value = [];

                for (const model of requestArray.value) {
                    rows.value.push(convertToProxy(model, rowNumber));
                    rowNumber++;
                }
            }

            if (props.mode == 'mix') {
                for (const model of requestArray.value) {
                    rows.value.push(convertToProxy(model, rowNumber));
                    rowNumber++;
                }

                requestArray.value = [];
                requestArray.value = rows.value;
            }
        };

        //Datatable Rows' data type is a Proxy that's why we need to convert
        //Concatenate the existing model with "rn" (default by datatable) as row number
        //add isForm attribute to tell that this data row is a form
        const convertToProxy = (model, counter) => {
            let tmpRow = {
                rn: counter.toString(),
                isForm: true,
                ...model,
            };

            return new Proxy(tmpRow, handler);
        };

        const addNumberOfRows = (numRows) => {
            for (let i = 0; i < numRows; i++) {
                formsRow({ increment: true });
            }
        };

        const onRowEditSave = (event) => {
            let data = event.data;
            let index = event.index;

            rows.value[index] = data;
            requestArray.value = rows.value;
        };

        const saveForms = () => {
            let payload = [];
            payload = { [tableName.value]: requestArray.value };

            nimibusService.value.saveBulk(submitApi.value, payload, notifService, toast);
        };

        const onRowReorder = (event) => {
            loading.value = true;
            nimibusService.value.updateReorder(reorderApi.value, event.value, rows.value, reorderColName.value).then(() => {
                // override here to update reordered base on array for forms
                loadLazyData();
                loading.value = false;
            });
            rows.value = event.value;
        };

        const onRowExpand = () => {
            // override method here if necessary
        };
        const onRowCollapse = () => {
            // override method here if necessary
        };

        const rowClass = (rowData) => {
            return rowData[childRow.value] !== undefined && rowData[childRow.value] !== null && rowData[childRow.value].length > 0 ? '' : 'no-expander';
        };

        const collapseAll = () => {
            expandedRows.value = null;
            // override method here if necessary
        };

        return {
            dt,
            loading,
            totalRecords,
            rows,
            selectedRows,
            lazyParams,
            loadLazyData,
            filters,
            globalFilters,
            defaultColumns,
            onPage,
            onSort,
            onFilter,
            selectAll,
            onSelectAllChange,
            onRowSelect,
            onRowUnselect,
            exportCSV,
            clearFilter,
            isPaginate,
            columnFilter,
            selectedColumns,
            onToggle,
            initFilter,
            globalSearch,
            filterItems,
            searchTooltip,
            sortDefault,
            clearSearch,
            resetColumnIcons,
            showExport,
            showColToggle,
            showClearAll,
            rowCount,
            disableTeleport,
            isReady,
            rowFormCount,
            rowForm,
            parseRowValue,
            isSearchResult,
            requestArray,
            formsRow,
            paginator,
            handler,
            numberOfRows,
            addNumberOfRows,
            hasPermission,
            editingRows,
            expandedRows,
            reorderColName,
            onRowEditSave,
            saveForms,
            alignPositionStyle,
            alignHeaderPositionClass,
            onRowReorder,
            rowClass,
            onRowExpand,
            onRowCollapse,
            childRow,
            collapseAll,
            items,
            showAllData,
            hasDefaultForm,
        };
    },
};
</script>

<style>
.p-datatable .p-paginator-bottom {
    border-top: 0;
    padding-top: 10px;
}

.header-right > .p-column-header-content {
    float: right;
}

.header-center > .p-column-header-content {
    text-align: center;
    display: block;
}

.nimbus-button-add-more button,
.nimbus-button-add-more button:hover {
    background: none !important;
    color: var(--primary-color) !important;
}

.nimbus-button-add-more .p-splitbutton-menubutton {
    border-left: none;
}

.custom-date-line:after {
  border-top: 1px solid #e9ecef;
  display: block;
  height: 1px;
  content: " ";
  width: 17%;
  position: absolute;
  left: 0;
  top: 1.2em;
}

.custom-date-line:after {
  margin-left : 90px;
}
</style>
