const page = 'sp-payment';

export default [
    {
        path: '/finance/' + page,
        name: `${page}-main`,
        exact: true,
        component: () => import('@/pages/finance/sp_payments/Index'),
        meta: {
            auth: true,
            permission: ['finance.account-receivables.payment.access'],
            title: 'Payments',
            prefix: `${page}-main`
        },
        children: [
            {
                path: `/finance/${page}/payments`,
                name: `${page}-payments`,
                exact: true,
                component: () => import('@/pages/finance/sp_payments/payments/payments/PaymentMain'),
                meta: {
                    auth: true,
                    permission: ['finance.account-receivables.payment.access'],
                    title: 'Payments',
                    prefix: `${page}-payments`,
                },
            },
            {
                path: `/finance/${page}/invoice`,
                name: `${page}-payment-methods`,
                exact: true,
                component: () => import('@/pages/finance/account_receivables/invoice/InvoiceMain'),
                meta: {
                    auth: true,
                    permission: ['finance.account-receivables.invoice'],
                    title: 'Invoices',
                    prefix: `${page}-invoice`,
                },
            },
            
        ]
    },

];
