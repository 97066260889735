export default [
    {
        path: '/account-verified',
        name: 'account.verified',
        exact: true,
        component: () => import('@/pages/end_user_portal/account_verification/Index.vue'),
        meta: {
            auth: false,
        },
    },
    {
        path: '/portal/account-security',
        name: 'Account Update Password',
        exact: true,
        component: () => import('@/pages/user_profile/Security'),
        meta: {
            auth: true,
            permission: ['user-profile.access', 'portal.account.security.access'],
            title : 'Account Security'
        },
    },
    {
        path: '/portal/account-summary',
        name: 'Account Summary',
        exact: true,
        component: () => import('@/pages/end_user_portal/account_summary/Main'),
        meta : {
            title : 'Account Summary',
            prefix: 'eup-as',
        }
    }
];
